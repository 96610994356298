import React from 'react';
import Link from 'next/link';
import { useLoading } from 'utils/useLoading';
import { LoadingScreen } from 'components';
import { NextSeo } from 'next-seo';

function Page404() {
  const loading = useLoading(false);

  if (loading) return <LoadingScreen />;

  return (
    <>
      <NextSeo title="Page not found" />

      <div className="page-404">
        <div className="content">
          <h1 className="heading">404</h1>
          <p className="subheading">
            Looks like the page you were looking for is no longer here.
          </p>
        </div>

        <Link href="/">
          <button className="button-404">Back to home page</button>
        </Link>
      </div>
    </>
  );
}

export default Page404;
